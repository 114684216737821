// app.js
import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import registerTailwindComponents from './registerTailwindComponents';

const alertsApp = createApp();
alertsApp.use(registerTailwindComponents);

alertsApp.mixin(Sentry.createTracingMixins({ trackComponents: true }));
Sentry.attachErrorHandler(alertsApp);

alertsApp.mount('#alerts');
